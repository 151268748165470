import React from "react";
import "../../css/content/global/_header.scss";
import logo from "../../assets/logo.svg";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Langs from "../functions/Langs";
import Menu from "./Menu";
import Burger from "./Burger";
import { useState } from "react";

function Header() {
  const { t, i18n } = useTranslation();
  return (
    <div className="header-wrapper">
      <div className="header-container">
        <NavLink to="/">
          <div className="logo-area">
            <img
              src={logo}
              title="Aurelius Inkasso & Õigusabi"
              alt="aurelius-logo"
              className="logo"
            ></img>

            <div className="logo-text">
              <div className="logo-name">AURELIUS</div>
              <div className="logo-description">{t("logo-sub")}</div>
            </div>
          </div>
        </NavLink>
        <div className="menu">
          <Menu />
        </div>
        <div className="burger-container">
          <Burger />
        </div>
        <div className="langs">
          <Langs />
        </div>
      </div>
    </div>
  );
}

export default Header;
