import React from "react";

function NotFound() {
  return (
    <div className="content">
      <div className="content-real notfound">
        <h1>404</h1>
        <p className="notfound-title">Page not found.</p>
        <span className="notfound-subtitle">
          Check for any mistakes in the URL
        </span>
        <a href="/" className="hero-button">
          Home
        </a>
      </div>
    </div>
  );
}

export default NotFound;
