import Header from "./components/global/Header";
import "./App.css";
import "./css/main.scss";
import { Route, Routes } from "react-router-dom";
import Services from "./components/pages/Services";
import Debtors from "./components/pages/Debtors";
import Pricing from "./components/pages/Pricing";
import Contact from "./components/pages/Contact";
import Banner from "./components/pages/Banner";
import Footer from "./components/global/Footer";
import Admin from "./components/pages/Admin";
import Login from "./components/pages/Login";
import NotFound from "./components/global/NotFound";
import { HelmetProvider } from "react-helmet-async";


function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <div className="bg"></div>
        <Header />

        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" exact element={<Banner />} />
          <Route path="/services" exact element={<Services />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="/pricing" exact element={<Pricing />} />
          <Route path="/debtors" exact element={<Debtors />} />
          <Route path="/adminview" exact element={<Admin />} />
          <Route path="/login" exact element={<Login />} />
        </Routes>
        <Footer />
      </div>
    </HelmetProvider>
  );
}

export default App;
