import React from 'react';
import '../../css/content/pages/_pricing.scss';
import { useTranslation } from 'react-i18next';

function Pricing() {

    const [t, i18n] = useTranslation();
  return (
    <div className="content">
      <div className="content-real">
        <h1 className="content-heading">{t("Hinnakiri")}</h1>
        <div className="services-list">
          <ul>
            <li className="pricing-list">
              <span>{t("Inkasso")}</span>
              <strong className='price' >5 - 35%</strong>
            </li>
            <li className="pricing-list">
              <span>{t("Kohtumenetlus")}</span>
              <strong>140 € / {t("tund")}</strong>
            </li>
            <li className="pricing-list">
              <span>
                {t("Juriidiline konsultatsioon ja esindamine")}
              </span>
              <strong>140 € / {t("tund")}</strong>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Pricing