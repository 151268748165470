import ee from "../../assets/flags/EE.svg";
import gb from "../../assets/flags/GB.svg";
import ru from "../../assets/flags/RU.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";

function Langs() {
  const { t, i18n } = useTranslation();
  const [dropdown, setDropdown] = useState();

  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  const activeLang = localStorage.getItem("lng") || "ee";

  const toggleDropdown = () => {
    setDropdown((current) => !current);
  };

  return (
    <div className="language">
      <div
        onClick={toggleDropdown}
        className={dropdown ? "choose-lang active" : "choose-lang"}
      >
        <div className="current-lang">
          {activeLang === "ee" && (
            <img src={ee} title="estonian-flag" alt="estonian-flag"></img>
          )}
          {activeLang === "en" && (
            <img
              src={gb}
              title="great-britain-flag"
              alt="great-britain-flag"
            ></img>
          )}
          {activeLang === "ru" && (
            <img src={ru} title="russian-flag" alt="russian-flag"></img>
          )}
        </div>

        <div className={dropdown ? "dropdown" : "nodropdown"}>
          <ul>
            <li onClick={() => handleChangeLng("ee")}>
              <img src={ee} alt="estonian-flag" title="estonian-flag"></img>
              <span>EE</span>
            </li>
            <li onClick={() => handleChangeLng("en")}>
              <img
                src={gb}
                alt="great-britain-flag"
                title="great-britain-flag"
              ></img>
              <span>EN</span>
            </li>
            <li onClick={() => handleChangeLng("ru")}>
              <img src={ru} alt="russian-flag" title="russian-flag"></img>
              <span>RU</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Langs;
