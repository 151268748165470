import React, { useState, useEffect } from "react";
import "../../css/content/pages/_debtors.scss";
import { useTranslation } from "react-i18next";

const Debtors = () => {
  const [debtors, setDebtors] = useState([]);
  const { t } = useTranslation();
  
  useEffect(() => {
    fetch(
      "https://aurelius-29705-default-rtdb.europe-west1.firebasedatabase.app/volgnikud/-NOKM1laZcxbbGcDPP3O.json"
    )
      .then((res) => res.json())
      .then((data) => setDebtors(Object.values(data).reverse()));
  }, []);

  return (
    <div className="content">
      <div className="content-real">
        <h1 className="content-heading">{t("Võlgnike nimekiri")}</h1>
        <div className="debtors-container">
          <h2 className="subtitle">{t("Isikud, kes ei täida oma kohustusi")}</h2>

          <div className="debtors-table">
            <div className="table-header">
              <div className="company-name">{t("Nimi")}</div>
              <div className="registry-col">{t("Registrikood")}</div>
              <div className="board-name">{t("Juhatus")}</div>
            </div>

            {debtors.map((debtor, index) => (
              <div key={index} className="table-row">
                <div className="company-name">{debtor.company}</div>
                <div className="registry-col">{debtor.registryCode}</div>
                <div className="board-name">{debtor.board}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Debtors;