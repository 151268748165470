import React from "react";
import "../../css/content/pages/_services.scss";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";


function Services() {
  const [t, i18n] = useTranslation();

  const activeLang = localStorage.getItem("lng") || "EE";
  console.log(activeLang)

  return (
    <div className="content">
      <Helmet>
        <title>{t("Teenused")}</title>
        <meta
          name="description"
          content="Inkasso, juriidiline nõustamine ja kohtumenetlus"
        />
      </Helmet>
      <div className="content-real">
        <h1 className="content-heading">{t("Teenused")}</h1>
        <div className="services-list">
          <ul>
            <li>{t("Nõuete kohtuväline menetlus")}</li>
            <li>{t("Kohtumenetlus")}</li>
            <li>
              {t("Juriidiline konsultatsioon ja õigusabi ")}
              <br />

              {t("(lepingu- ja võlaõigus, äriõigus, pankrotiõigus)")}
            </li>
            <li>
              {t(
                "Esindamine läbirääkimistel, kohtumenetluses ja pankrotimenetluses"
              )}
              {activeLang === "en" && (
                <div className="reps">
                  <div className="rep-head">Representation in:</div>
                  <div className="representation-list">
                    <span>Negotiations, </span>
                    <span>Dispute resolution, </span>
                    <span>Pre-trial disputes and judicial proceedings, </span>
                    <span>Bankruptcy proceedings,</span>
                    <span>Liquidation proceedings</span>
                  </div>
                </div>
              )}
            </li>
            <li>{t("Õigusalaste dokumentide koostamine")}</li>
            <li>{t("Krediidiinfo ja taustauuringud")}</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Services;
