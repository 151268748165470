import "../../css/content/global/_menu.scss";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BurgerMenu from "./Burger";

export const menuItems = [
  { address: "/services", name: "Teenused" },
  { address: "/contact", name: "Kontakt" },
  { address: "/pricing", name: "Hinnakiri" },
  { address: "/debtors", name: "Võlgnikud" },
];

const Menu = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="content-container">
      <div className="menu-container">
        <div className="burger-container">
          <BurgerMenu />
        </div>

        <div className="menu">
          <div className="menu-items">
            {menuItems.map((element) => (
              <NavLink
                to={element.address}
                className="menu-link"
                activeClassName="menu-link active"
              >
                <div className="menu-item">{t(`${element.name}`)}</div>
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
