import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../service/firebase";
import { useNavigate } from "react-router-dom";
import { Button } from "@chakra-ui/react";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        navigate("/adminview");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  return (
    <>
      <main>
        <section>
          <div className="content">
            <form>
              <div className="content-real login">
                <div className="login-input">
                  <label htmlFor="email-address">Email</label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    required
                    placeholder="Type email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="login-input">
                  <label htmlFor="password">Password</label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    placeholder="Type password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div>
                  <Button onClick={onLogin}>Login</Button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
    </>
  );
};

export default Login;
