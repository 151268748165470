import React from "react";
import "../../css/content/pages/_banner.scss";
import { useTranslation } from "react-i18next";

function Banner() {
  const [t, i18n] = useTranslation();
  return (
    <div className="banner-wrapper">
      <div className="main-banner">
        <h1 className="hero-heading">
          {t("Inkasso")} <br /> & <br />
          {t("Õigusabi")}{" "}
        </h1>
        <p className="hero-sub">
          {t(
            "Meie meeskonna eeliseks on pikaaegne kogemus ning kiire ja selge suhtlus."
          )}
        </p>
        <a
          href="/contact"
          title="Contact page"
          className="hero-button"
        >
          {t("Võta ühendust")}
        </a>
        <div className="quote-container">
        <div className="quote">
          "{t("Elamiskunst sarnaneb enam maadlus- kui tantsukunstiga.")}"
          <span>{t("- Marcus Aurelius")}</span>
        </div>
        </div>
        
        <div className="slight-blur"></div>
      </div>
    </div>
  );
}

export default Banner;
