import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import burger from "../../assets/burger.svg";
import burgerclose from "../../assets/burger-close.svg";
import { menuItems } from "../global/Menu";
import Langs from "../../components/functions/Langs";
import rightarrow from "../../assets/rightarrow.svg";
import Footer from "./Footer";

function Burger() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.position = 'static';
    }
    
    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.position = 'static';
    };
  }, [isOpen]);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <div className="burger">
      {!isOpen && (
        <button 
          onClick={toggleMenu} 
          className="open-burger"
          aria-label="Open menu"
        >
          <img src={burger} alt="" />
        </button>
      )}

      {isOpen && (
        <div 
          className="opened-burger" 
          role="dialog" 
          aria-modal="true"
        >
          <div className="overlay">
            <div className="overlay__background">
              <div className="overlay__container">
                <div className="burger-langs">
                  <Langs />
                </div>
                <button
                  onClick={toggleMenu}
                  className="close-burger"
                  aria-label="Close menu"
                >
                  <img src={burgerclose} alt="" />
                </button>
                <nav className="burger-links" aria-label="Main">
                  <div className="menu-tag">Menüü</div>
                  {menuItems.map((element) => (
                    <NavLink
                      key={element.address}
                      to={element.address}
                      className={({ isActive }) => 
                        `menu-link-burger ${isActive ? 'active' : ''}`
                      }
                      onClick={toggleMenu}
                    >
                      {t(element.name)}
                      <img src={rightarrow} alt="" />
                    </NavLink>
                  ))}
                </nav>
              </div>
              <footer className="burger-footer">
                <Footer />
              </footer>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Burger;