import React from "react";
import { useTranslation } from "react-i18next";

function Footer() {
  const currentYear = new Date().getFullYear();

  const [t, i18n] = useTranslation();
  return (
    <div className="footer-wrapper">
      <div className="footer-container">
        <div className="footer-contact">
          <span>info@aurelius.ee</span>•<span>+372 555 86 485</span>
        </div>
        <div className="footer-signature">© {currentYear} Aurelius Inkasso OÜ</div>
      </div>
    </div>
  );
}

export default Footer;
